// export const supportedCountries = countryCodeJSON.map(country => ({
//   optionName: country.name,
//   optionValue: country.code,
//   code: country.code,
// }));
// export const supportedCurrencies = currencyJSON.map(currency => ({
//   optionValue: createCurrencyString(currency),
//   code: currency.Code,
//   optionName: currency.CountryName
// }));

export const invalidCurrencyCodes = [
  'BMD',
  'CUP',
  'GGP',
  'GIP',
  'KPW',
  'LRD',
  'PAB',
  'SHP',
  'SOS',
  'SRD',
  'SVC',
  'TVD',
  'UZS',
  'ZWD',
];

export const validCountryCodes = ['GB'];

// matches the names in the translation json
export const templateText = [
  {
    title: 'full_template',
    templateLink: '/download-templates/wf-mdi-template.csv',
    backgroundColor: 'bg-highlight bg-opacity-50',
  },
  {
    title: 'excel_template',
    templateLink: '/download-templates/wf-mdi-excel-template.xlsx',
    backgroundColor: 'bg-highlight bg-opacity-50',
  },
  {
    title: 'excel_full_template',
    templateLink: '/download-templates/Client_Input_Sheet_v1.03.xlsm',
    backgroundColor: 'bg-highlight-3 bg-opacity-50',
  },
];
export const orbisAvailableSearchCountries = [
  'al',
  'at',
  'by',
  'be',
  'ba',
  'bg',
  'hr',
  'cy',
  'cz',
  'dk',
  'ee',
  'fi',
  'fr',
  'de',
  'gi',
  'gr',
  'hu',
  'is',
  'ie',
  'it',
  'kv',
  'lv',
  'li',
  'lt',
  'lu',
  'mt',
  'md', // not found
  'mc', // not found
  'nl',
  'mk',
  'me',
  'no',
  'pl',
  'pt',
  'ro',
  'ru',
  'rs', // not found
  'sk',
  'si',
  'es',
  'se',
  'ch',
  'tr',
  'ua',
];

// while writing unit tests for the SearchContainer, the following countries
// included in the Orbis list don't have a match, so fail the tests.
// Including the list here so I can filter them out in the tests but
// keep them in the UI since I don't know if it will break them
// - Jack
export const orbisUnavailableCountries = ['md', 'mc', 'rs'];
